import { UrlObject } from 'url';

type QuerySearchParams = string | string[];
type QueryRelativePath = string | string[];

interface RestrictedQuery {
  searchParams?: QuerySearchParams;
  relativePath?: QueryRelativePath;
}

export type RestrictedUrlObject = Pick<UrlObject, 'pathname'> & {
  query?: RestrictedQuery;
};

export type RestrictedHrefType = string | RestrictedUrlObject;

export interface RestrictedHref {
  href?: RestrictedHrefType;
}

export function addLocaleToPath(path: string, locale: string): string {
  if (path.startsWith(`/${locale}/`) || path === `/${locale}`) {
    return path;
  }
  return `/${locale}${path.startsWith('/') ? path : `/${path}`}`;
}

export function handleStringHref(href: string, locale: string | false): string {
  if (!locale) {
    return href;
  }
  return addLocaleToPath(href, locale);
}

export function getBasePath(pathname: string): string {
  const pathParts = pathname.split('/').filter(Boolean);
  return pathParts.length > 0 ? `/${pathParts[0]}` : '';
}

export function handleSearchParams(query: unknown): string[] {
  const params: string[] = [];
  if (query && typeof query === 'object') {
    if ('relativePath' in query) {
      const relativePath = query.relativePath;
      if (Array.isArray(relativePath)) {
        params.push(...relativePath);
      } else if (typeof relativePath === 'string') {
        params.push(relativePath);
      }
    }
    if ('searchParams' in query) {
      const searchParams = query.searchParams;
      if (Array.isArray(searchParams)) {
        params.push(...searchParams);
      } else if (typeof searchParams === 'string') {
        params.push(searchParams);
      }
    }
  }
  return params;
}

export function combineBasePathAndParams(basePath: string, params: string[]): string {
  return params.length > 0 ? `${basePath}/${params.join('/')}` : basePath;
}

export function resolveHref(
  href: RestrictedHrefType,
  locale: string | false = false,
  currentPathname = '/'
): string {
  if (typeof href === 'string') {
    return handleStringHref(href, locale);
  }

  // Handle UrlObject
  const { pathname: hrefPathname, query } = href;
  let finalPathname = hrefPathname ?? currentPathname;

  if (locale) {
    finalPathname = addLocaleToPath(finalPathname, locale);
  }

  const basePath = getBasePath(finalPathname);
  const newParams = handleSearchParams(query);
  return combineBasePathAndParams(basePath, newParams);
}
