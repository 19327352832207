import * as Icons from 'icons/iconic';
import { CheckIcon } from 'icons/monochrome/CheckIcon';
import { useMemo } from 'react';
import { IUIComponentItemFields } from 'types/generated/contentful';

export const ICONS = {
  AnnotationDotsIcon: Icons.AnnotationDotsIcon,
  BuildingStoreIcon: Icons.BuildingStoreIcon,
  CalendarPlusIcon: Icons.CalendarPlusIcon,
  ClockIcon: Icons.ClockIcon,
  CompassIcon: Icons.CompassIcon,
  CrosshairIcon: Icons.CrosshairIcon,
  Dollar2Icon: Icons.Dollar2Icon,
  DollarIcon: Icons.DollarIcon,
  FileText2Icon: Icons.FileText2Icon,
  MapIcon: Icons.MapIcon,
  NavigationIcon: Icons.NavigationIcon,
  ParkingIcon: Icons.ParkingIcon,
  RouteIcon: Icons.RouteIcon,
  TrailerIcon: Icons.TrailerIcon,
  UserCheckIcon: Icons.UserCheckIcon,
  WarningTriangleIcon: Icons.WarningTriangleIcon
};

/**
 * Returns icon given Contentful icon field
 * @param {icon} IUIComponentItemFields['icon']
 * @returns memoized icon
 */
const useIcon = (icon: IUIComponentItemFields['icon']) => {
  return useMemo(() => {
    return ICONS[icon] ?? CheckIcon;
  }, [icon]);
};

export { useIcon };
