'use client';
import { TranslationConfig, useTranslation } from 'hooks/useContentful/useTranslation';
import React from 'react';
import { TranslationKeys } from 'types/translationKeys';

export type TranslationProps = {
  // key is a reserved word for props ¯\_(ツ)_/¯
  textKey: TranslationKeys;
} & TranslationConfig;

export const Translation = React.memo(({ textKey, ...config }: TranslationProps) => {
  const { t } = useTranslation();

  return <>{t(textKey, config)}</>;
});

Translation.displayName = 'Translation';
