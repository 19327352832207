'use client';
import { Transition } from '@headlessui/react';
import { Translation } from 'components/common/Translation';
import { Props as ScrollYProps, useScrollButton } from 'hooks/useUI/useScrollButton';
import { makeStyles } from 'lib/makeStyles';
import { commonOpacityTransition } from 'lib/transitionProps/transitionProps';
import React, { memo } from 'react';

import { HeroIcon } from '../HeroIcon';
import { SmallText } from '../Typography';
import { RoundButton } from './RoundButton';

export const useScrollButtonStyles = makeStyles<ParentClassNameProp>()({
  root: (props) => [props.className],
  innerWrapper: ({ className }: ParentClassNameProp) => [
    'p-4 mx-auto',
    'hover:shadow-lg',
    className
  ],
  text: 'whitespace-nowrap',
  icon: 'w-5 h-5 bg-white mr-1'
});

export type ScrollButtonProps = ScrollYProps &
  ParentClassNameProp &
  VisibilityProp &
  ChildrenProp &
  ClickEventProp;

export const ScrollButton: React.FC<ScrollButtonProps> = memo(
  ({ className, minScrollY, show = false, children, onClick }) => {
    const { scrollToTop, showButton } = useScrollButton({ minScrollY });
    const styles = useScrollButtonStyles({ className });

    return (
      <Transition
        as="div"
        className={styles.root}
        show={showButton || show}
        {...commonOpacityTransition}>
        <RoundButton intent="dark" onClick={onClick ?? scrollToTop} className={styles.innerWrapper}>
          {children ?? (
            <>
              <HeroIcon name="arrow-up" type="solid-mini" className={styles.icon} />
              <SmallText color="primary" dark enableHover="group" className={styles.text}>
                <Translation textKey={'SCROLL_TO_TOP_BUTTON'} />
              </SmallText>
            </>
          )}
        </RoundButton>
      </Transition>
    );
  }
);

ScrollButton.displayName = 'ScrollButton';
