import { LatLon } from '@truckmap/common';
import turfCircle from '@turf/circle';
import { Feature, Point } from '@turf/helpers';
import { useMapSource } from 'hooks/useMap/useMapSource/useMapSource';
import { useMapSourceCoordinates } from 'hooks/useMap/useMapSource/useMapSourceCoordinates';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { pointsOfInterestAtom } from 'recoil/pointsOfInterest';

export enum RoutePlannerMapSources {
  ROUTE = 'route',
  ACTIVE_DIRECTION = 'active_direction',
  ROUTE_CLUSTERS = 'route_clusters',
  CLUSTERS = 'clusters',
  CURRENT_LOCATION = 'current_location',
  CURRENT_LOCATION_RADIUS = 'current_location_radius'
}

export type RoutePlannerCurrentLocationSource = Feature<
  Point,
  {
    tripId: string;
    precise: boolean;
  }
>;

export type RoutePlannerSourceConfig = {
  currentLocationGeoPoint: RoutePlannerCurrentLocationSource;
} & Required<LatLon>;

export const emptyFeature: Feature<null> = {
  type: 'Feature',
  properties: {},
  geometry: null
};

export function createCurrentLocationRadiusSource(
  currentLocationGeoPoint: RoutePlannerCurrentLocationSource
) {
  return turfCircle(currentLocationGeoPoint, 800, {
    steps: 800,
    units: 'meters',
    properties: currentLocationGeoPoint.properties
  });
}

export const useRoutePlannerMapSource = ({
  currentLocationGeoPoint,
  latitude,
  longitude
}: RoutePlannerSourceConfig) => {
  const [{ places, active }] = useRecoilState(pointsOfInterestAtom);
  const mapCoordinatesSource = useMapSourceCoordinates(places);

  const { sources, actions } = useMapSource({
    mapCoordinatesSource,
    data: places,
    layerId: RoutePlannerMapSources.CLUSTERS,
    placeSlug: active,
    defaultAsset: undefined
  });

  const mainSources = useMemo(
    () =>
      sources()
        .set(RoutePlannerMapSources.CURRENT_LOCATION, {
          type: 'geojson',
          data: currentLocationGeoPoint
        })
        .set(RoutePlannerMapSources.CURRENT_LOCATION_RADIUS, {
          type: 'geojson',
          data: createCurrentLocationRadiusSource(currentLocationGeoPoint)
        })
        .set(RoutePlannerMapSources.ROUTE, {
          type: 'geojson',
          data: emptyFeature
        })
        .set(RoutePlannerMapSources.ACTIVE_DIRECTION, {
          type: 'geojson',
          data: emptyFeature
        }),
    [sources, latitude, longitude]
  );

  return { actions, sources: mainSources };
};
