import { ICompany, IPlace, IPlaceMetaType, IPlaceType } from '@truckmap/common';
import { AssetIconType } from 'components/common/AssetIcon';
import {
  hasPlaceCompanies,
  isPlace,
  isPlaceCompany,
  isPlaceType,
  SearchResultPartial
} from 'lib/place/isPlace';

/**
 * Returns slug for a company or place icon
 * @param place: IPlace | ICompany
 * @returns slug:string
 */

export type PlaceIconAnnotation = { slug: string; type: AssetIconType };

const usePlaceIcon = (item: SearchResultPartial): PlaceIconAnnotation => {
  if (isPlaceCompany(item as ICompany)) {
    return { slug: (item as ICompany).slug, type: 'companies' };
  }

  if (hasPlaceCompanies(item as IPlace)) {
    return { slug: (item as IPlace)?.companies[0].slug, type: 'companies' };
  }

  if (isPlace(item as IPlace)) {
    return { slug: (item as IPlace)?.placeType?.slug, type: 'place-types' };
  }

  if (isPlaceType(item as IPlaceType)) {
    return {
      slug: (item as IPlaceType)?.slug,
      type: 'place-types'
    };
  }

  if (item.objectType === 'place_meta_type') {
    return { slug: (item as IPlaceMetaType).asset?.slug, type: 'place-meta-types' };
  }

  // fallback slug
  return {
    slug: 'truck-stop',
    type: 'place-types'
  };
};

export { usePlaceIcon };
