import { IPlaceOverview } from '@truckmap/common';
import { atom } from 'recoil';
import { persistanceCacheEffect } from 'recoil/persistanceCacheEffect';

export type PointsOfInterest = 'fuel' | 'weigh-station' | 'parking';

export type PointOfInterestPlace = Partial<IPlaceOverview>;

export type PointsOfInterestAtom = {
  active: PointsOfInterest;
  open: boolean;
  places: PointOfInterestPlace[];
  boundingBox?: [minLat: number, minLon: number, maxLat: number, maxLon: number];
};

export const pointsOfInterestAtom = atom<PointsOfInterestAtom>({
  key: 'pointsOfInterestAtom',
  default: {
    active: 'fuel',
    open: false,
    places: []
  },
  effects: [persistanceCacheEffect.persistAtom]
});
