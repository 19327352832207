'use client';
import { useToggle } from 'hooks/useToggle';
import React, { useEffect } from 'react';
import { truckMapConfig } from 'truckMapConfig';

const ClientSideOnly: React.ComponentType<ChildrenProp> = React.memo(({ children }) => {
  const { status: isHydrationReady, enable: setHydrationReady } = useToggle();

  useEffect(() => {
    truckMapConfig.isClientSideRender && setHydrationReady();
  }, []);

  return isHydrationReady ? <>{children}</> : null;
});
ClientSideOnly.displayName = 'ClientSideOnly';

export { ClientSideOnly };
