import { PanelItemProps } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';

export type PanelAsProp = {
  as?: 'div' | 'span' | 'li' | 'nav' | 'label' | 'ul' | 'address' | 'section';
};

export type PanelRelativeProp = {
  relative?: boolean;
};

export type PanelSizeProps = {
  fullHeight?: boolean;
  fullWidth?: boolean;
};

export type PanelPaddingProps = {
  padding?: boolean;
  paddingX?: false | 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | '2XL';
  paddingY?: false | 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | '2XL';
};

export const usePanelPaddingStyles = makeStyles<PanelItemProps>()({
  padding: (props) => [
    {
      'py-0.5': props.paddingY === 'XS' && props.padding,
      'py-1': props.paddingY === 'SM' && props.padding,
      'py-1.5': props.paddingY === 'MD' && props.padding,
      'py-2': props.paddingY === 'LG' && props.padding,
      'py-3': props.paddingY === 'XL' && props.padding,
      'py-4': props.paddingY === '2XL' && props.padding,
      'px-1': props.paddingX === 'XS' && props.padding,
      'px-2': props.paddingX === 'SM' && props.padding,
      'px-3': props.paddingX === 'MD' && props.padding,
      'px-4': props.paddingX === 'LG' && props.padding,
      'px-5': props.paddingX === 'XL' && props.padding,
      'px-6': props.paddingX === 'XL' && props.padding,
      'py-0': !props.paddingY || !props.padding,
      'px-0': !props.paddingX || !props.padding
    }
  ]
});

export const usePanelSpacingStyles = makeStyles<PanelItemProps>()({
  spacing: (props) => [
    {
      'gap-x-1': !props.column && props.spacing === 'XS',
      'gap-y-0.5': props.column && props.spacing === 'XS',
      'gap-x-1.5': !props.column && props.spacing === 'SM',
      'gap-y-1': props.column && props.spacing === 'SM',
      'gap-x-2': !props.column && props.spacing === 'MD',
      'gap-y-1.5': props.column && props.spacing === 'MD',
      'gap-x-3': !props.column && props.spacing === 'LG',
      'gap-y-2.5': props.column && props.spacing === 'LG',
      'gap-x-4': !props.column && props.spacing === 'XL',
      'gap-y-5': props.column && props.spacing === 'XL',
      'gap-x-6': !props.column && props.spacing === '2XL',
      'gap-y-7': props.column && props.spacing === '2XL',
      'gap-y-0 gap-x-0': props.spacing === false
    }
  ]
});

export const usePanelFlexStyles = makeStyles<PanelItemProps>()({
  flex: (props) => [
    'flex',
    {
      'flex-row': props.row && !props.column && !props.reverse && !props.responsiveLayout,
      'flex-row-reverse': props.row && !props.column && props.reverse && !props.responsiveLayout,
      'flex-col': props.column && !props.reverse && !props.responsiveLayout,
      'flex-col-reverse': props.column && props.reverse && !props.responsiveLayout,
      'flex-col md:flex-row':
        props.row && !props.column && !props.reverse && props.responsiveLayout,
      'flex-col-reverse md:flex-row-reverse':
        props.row && !props.column && props.reverse && props.responsiveLayout,
      'flex-row md:flex-col': props.column && !props.reverse && props.responsiveLayout,
      'flex-row-reverse md:flex-col-reverse':
        props.column && props.reverse && props.responsiveLayout,
      [`flex-${props.wrap !== true ? props.wrap : 'wrap'}`]: props.wrap,
      [`justify-${props.justify}`]: props.justify,
      [`items-${props.align}`]: props.align
    }
  ]
});

export const usePanelRoundedStyles = makeStyles<PanelItemProps>()({
  rounded: (props) => [
    {
      rounded: props.rounded === 'MD' || props.rounded === true,
      'rounded-sm': props.rounded === 'SM',
      'rounded-lg': props.rounded === 'LG',
      'rounded-full': props.rounded === 'full',
      'rounded-none': props.rounded === false
    }
  ]
});

export const usePanelDividerStyles = makeStyles<PanelItemProps>()({
  divider: (props) => [
    {
      'divide-y divide-neutralBorder': props.showDivider
    }
  ]
});

export const usePanelShadowStyles = makeStyles<PanelItemProps>()({
  shadow: (props) => [
    {
      'shadow-lg': props.shadow === 'LG',
      'shadow-md': props.shadow === 'MD',
      'shadow-sm': props.shadow === 'SM' || props.shadow === true,
      'shadow-none': props.shadow === false
    }
  ]
});

export const usePanelSizeStyles = makeStyles<PanelItemProps>()({
  size: (props) => [{ 'w-full': props.fullWidth, 'h-full': props.fullHeight }]
});

export const usePanelRelativeStyles = makeStyles<PanelItemProps>()({
  relative: (props) => [{ relative: props.relative }]
});
