import { BypassSearchBot } from 'components/common/BypassSearchBot';
import { ClientSideOnly } from 'components/common/ClientSideOnly';
import dynamic from 'next/dynamic';

import type { MapProps } from './Map';

const MapComponentChunk = dynamic<MapProps>(() => import('./Map').then((mod) => mod.Map));

export const Map = (props: MapProps) => (
  <ClientSideOnly>
    <BypassSearchBot>
      <MapComponentChunk {...props} />
    </BypassSearchBot>
  </ClientSideOnly>
);
