import { MapControllers } from 'models/MapControllers';
import { createContext, useContext } from 'react';

type MapControllerProvider = {
  children: React.ReactNode;
  mapController: MapControllers;
};

const MapControllerContext = createContext(MapControllers.ROUTE_PLANNER);

const MapControllerProvider = ({ children, mapController }: MapControllerProvider) => {
  return (
    <MapControllerContext.Provider value={mapController}>{children}</MapControllerContext.Provider>
  );
};

const useMapControllerContext = () => {
  const context = useContext(MapControllerContext);
  if (!context) {
    throw new Error('useMapController must be used within a MapControllerProvider');
  }
  return context;
};

export { MapControllerProvider, useMapControllerContext };
