import { ICompany, IPlace, IPlaceType } from '@truckmap/common';
import { SearchResult } from '@truckmap/common/lib/cjs/types/place/SearchResult';
import isEmpty from 'lodash/isEmpty';

export type SearchResultPartial = Omit<SearchResult, 'IPlaceMetaType'>;

export const isPlace = (place: IPlace): boolean => !isEmpty(place?.placeType?.slug);
export const isPlaceType = (item: IPlaceType): boolean => item.objectType === 'place_type';
export const isPlaceCompany = (place: IPlace | ICompany): boolean => place.objectType === 'company';
export const isPlaceCity = (place: IPlace) => place?.placeType?.objectKey === 'place_type:city';

export const isPlaceCoordinate = (place: IPlace) =>
  place?.placeType?.objectKey === 'place_type:coordinates';

export const hasPlaceCompanies = (place: SearchResultPartial): boolean =>
  'companies' in place && !isEmpty((place as IPlace)?.companies);
