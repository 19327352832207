import { serializeComponent } from 'lib/contentful/contentfulSerializers';
import { mapEntriesByType } from 'lib/contentful/mapEntriesByType';
import { useMemo } from 'react';
import { SerializedComponent } from 'types/contentful';
import { IUIComponent } from 'types/generated/contentful';

export const useContentfulEntry = (_entry: IUIComponent) =>
  useMemo(() => serializeComponent(_entry), [_entry]);

export const useContentfulEntriesByType = (serializedEntries: SerializedComponent) =>
  useMemo(() => mapEntriesByType(serializedEntries.items), [serializedEntries.items]);

/**
 * Takes a UIComponent from contentful and serializes the component and items.
 * @param input UIComponent
 * @returns Entries serialized and organized by type.
 */
export function useContentfulSerializer(_entry: IUIComponent) {
  const entry = useContentfulEntry(_entry);
  const items = useContentfulEntriesByType(entry);

  return { entry, items };
}
