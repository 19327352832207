import va from '@vercel/analytics';
import { truckMapConfig } from 'truckMapConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pingEvent(eventName: string, ...args: any[]) {
  const normalizedEventName = eventName.toUpperCase().replaceAll(' ', '_');

  if (
    truckMapConfig.isClientSideRender &&
    !truckMapConfig.isStaging &&
    !truckMapConfig.isTenstreet
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)?.gtag('event', normalizedEventName, {
      ...args,
      group: truckMapConfig.isTenstreet ? 'Tenstreet' : 'TruckMap'
    });

    va.track(normalizedEventName, (args?.length && args[0]) || undefined);
  }
}
