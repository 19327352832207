import { ContentfulQuery, IPlace, IPlaceSummary } from '@truckmap/common';
import { getPrettySummary } from 'lib/place/getPrettySummary';
import { TranslationKeys } from 'types/translationKeys';

export type PlaceSummaryItem = {
  id: string;
  label: string;
  value: string;
};

const PlaceSummaryTranslationKeys = new Map<keyof IPlaceSummary, TranslationKeys>([
  ['availableParking', 'PLACE_SUMMARY_AVAILABLE_PARKING'],
  ['weighStationStatus', 'PLACE_SUMMARY_WEIGH_STATION_STATUS'],
  ['totalReviews', 'PLACE_SUMMARY_TOTAL_REVIEWS'],
  ['dieselPrice', 'PLACE_SUMMARY_DIESEL_PRICE'],
  ['averageRating', 'PLACE_SUMMARY_AVERAGE_RATING']
]);

export function parsePlaceSummary(
  place: IPlace,
  contentfulQuery: ContentfulQuery
): PlaceSummaryItem[] {
  const prettySummary = getPrettySummary(place?.summary);

  return Object.keys(prettySummary).map((summaryItem) => {
    const translationKey = PlaceSummaryTranslationKeys.get(summaryItem as keyof IPlaceSummary);

    return {
      id: summaryItem,
      label:
        contentfulQuery.commonCollection.items.find(({ key }) => key === translationKey)?.value ??
        summaryItem,
      value: prettySummary[summaryItem]
    };
  });
}
