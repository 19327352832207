import { SmallText } from 'components/common/Typography';
import { makeStyles } from 'lib/makeStyles';
import React, { useCallback } from 'react';
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef
} from 'react-polymorphic-types';
import { useButtonVariantStyles } from 'styles/variantStyles/buttonStyles';

export type ButtonOwnProps = {
  fullWidth?: boolean;
  isPrimary?: boolean;
  spacing?: false | 'SM' | 'MD' | 'LG';
  isSuccess?: boolean;
  dark?: boolean;
  decreaseBackgroundOpacity?: boolean;
  isSmall?: boolean;
  isError?: boolean;
  borderless?: boolean;
  textGray?: boolean;
  disabled?: boolean;
  href?: string;
  customClassName?: string;
  cursor?: 'default' | 'pointer';
} & ChildrenProp &
  ParentClassNameProp;

export type SupportedElementsType = 'button' | 'a';

export const ButtonDefaultElement: SupportedElementsType = 'button';

export type ButtonRefProps<T extends React.ElementType = SupportedElementsType> =
  PolymorphicPropsWithRef<ButtonOwnProps, T>;

export const useStyles = makeStyles<ButtonOwnProps & { buttonVariantStyles?: string }>()({
  root: (props) => [
    {
      [`cursor-${props.cursor}`]: !props?.disabled,
      'inline-flex justify-center items-center': !props.customClassName,
      'whitespace-nowrap': !props.customClassName,
      'w-full': props.fullWidth,
      'py-2': props.spacing === 'MD',
      'shadow-sm hover:shadow': !props.borderless,
      'px-3 h-10': !props.borderless && !props.isSmall,
      'px-2 h-8': !props.borderless && props.isSmall,
      'rounded-lg': !props.borderless && !props.isSmall,
      'rounded-md': !props.borderless && props.isSmall,
      'bg-opacity-75 hover:bg-opacity-75': props.decreaseBackgroundOpacity,
      [props.buttonVariantStyles]: !props.borderless && props.buttonVariantStyles,
      [props.customClassName]: props.customClassName
    }
  ],
  wrapper: (props) => [{ 'w-full': props.fullWidth }]
});

export const Button: PolymorphicForwardRefExoticComponent<ButtonOwnProps, SupportedElementsType> =
  React.forwardRef(function Button<T extends React.ElementType = SupportedElementsType>(
    {
      as,
      fullWidth,
      isPrimary,
      isSuccess,
      isError,
      isSmall,
      dark,
      disabled,
      children,
      textGray,
      customClassName,
      borderless,
      className,
      cursor,
      decreaseBackgroundOpacity,
      ...restProps
    }: PolymorphicPropsWithoutRef<ButtonOwnProps, T>,
    ref: React.ForwardedRef<Element>
  ) {
    const Element: React.ElementType = as || ButtonDefaultElement;

    const buttonVariantStyles = useButtonVariantStyles({
      intent: isPrimary ? 'primary' : dark ? 'secondary' : 'neutral',
      interaction: disabled ? 'disabled' : 'hover',
      mode: 'default'
    });

    const styles = useStyles({
      isError,
      fullWidth,
      isPrimary,
      isSuccess,
      isSmall,
      dark,
      disabled,
      textGray,
      customClassName,
      borderless,
      cursor,
      buttonVariantStyles,
      decreaseBackgroundOpacity
    });

    const renderButtonElement = useCallback(
      () => (
        <Element ref={ref} {...restProps} disabled={disabled} className={styles.root}>
          <SmallText
            as="span"
            whitespace="nowrap"
            className={styles.wrapper}
            color={
              textGray ? 'neutral' : isPrimary || isSuccess || isError || dark ? 'white' : 'primary'
            }>
            {children}
          </SmallText>
        </Element>
      ),
      [restProps, isPrimary, isSuccess, isError, dark, borderless, customClassName, disabled, ref]
    );

    return className ? (
      <div className={className}>{renderButtonElement()}</div>
    ) : (
      renderButtonElement()
    );
  });

Button.defaultProps = {
  cursor: 'pointer',
  spacing: 'MD',
  isPrimary: false,
  isSuccess: false,
  dark: false,
  isError: false,
  textGray: false,
  disabled: false,
  fullWidth: false,
  isSmall: false,
  borderless: false
};

Button.displayName = 'Button';
