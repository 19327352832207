import {
  buildLatLonForObject,
  fetchClosestCity,
  fetchPlace,
  IPlace,
  ITripWaypoint,
  LatLonObject
} from '@truckmap/common';
import { getPrimaryPlaceSlug } from 'lib/getPrimaryPlaceSlug';

// TODO: import from common
export const getPlaceCoordinates = (place: Partial<IPlace>) => buildLatLonForObject(place) ?? {};

export const getClosestCity = async (place: IPlace) => {
  const coordinates = getPlaceCoordinates(place);
  return await fetchClosestCity({
    lat: coordinates?.latitude,
    lon: coordinates?.longitude
  });
};

export const getClosestCityPrimary = async (place: IPlace) => {
  const closestCity = await getClosestCity(place);
  return await fetchPlace(closestCity.id);
};

export const getClosestCityPrimarySlug = async (place: IPlace) => {
  const closestCityPlace = await getClosestCityPrimary(place);

  return getPrimaryPlaceSlug(closestCityPlace);
};

export const getClosestCityPrimarySlugFromCoordinates = async (
  coordinates: LatLonObject | ITripWaypoint
) => {
  const place = await getClosestCityFromCoordinates(coordinates);

  return getPrimaryPlaceSlug(place);
};

export const getClosestCityFromCoordinates = async (coordinates: LatLonObject | ITripWaypoint) => {
  const closestCity = await fetchClosestCity({
    lat: coordinates?.latitude,
    lon: coordinates?.longitude
  });

  const place = await fetchPlace(closestCity.id);

  return place;
};
