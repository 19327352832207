'use client';
import { ContentfulCommon, ContentfulDataType, ContentfulQuery } from '@truckmap/common';
import { logWithFallback } from 'lib/error';
import { mustache } from 'lib/mustache';
import { useMemo } from 'react';
import { TranslationDataTypes, TranslationKeys } from 'types/translationKeys';

import { useContentfulQuery } from './useContentful';

export type TranslationVariables = {
  [n: string]: number | string;
};

export type TranslationConfig = {
  amount?: number;
  variables?: TranslationVariables;
};

export function useTranslation() {
  const contentfulQuery = useContentfulQuery();
  const translationMap = useMemo(() => {
    const translationFallback = { commonCollection: { items: [] } } as ContentfulQuery;
    const { commonCollection } = contentfulQuery?.commonCollection?.items?.length
      ? contentfulQuery
      : logWithFallback(
          'commonCollection item is not queried or item keys are not found',
          translationFallback,
          'error'
        );

    return new Map<string, ContentfulCommon>(
      commonCollection.items.map((translation) => [translation.key, translation])
    );
  }, [contentfulQuery]);

  return {
    translationMap,
    t(key: TranslationKeys, config?: TranslationConfig) {
      const { amount = 0, variables = Object.create(null) } = config ?? {};

      const translation =
        translationMap?.get(key) ??
        logWithFallback(
          `translation key ${key} not found or not queried`,
          { value: key, valuePlural: key },
          'warn'
        );

      return typeof amount === 'number' && (amount === 0 || amount > 1) && translation.valuePlural
        ? mustache(translation.valuePlural, variables)
        : mustache(translation.value, variables);
    }
  };
}

export function useTranslationDataType(type: TranslationDataTypes) {
  const contentfulQuery = useContentfulQuery();

  return useMemo(() => {
    const {
      dataTypeCollection: { items }
    } = contentfulQuery;

    return new Map<string, ContentfulDataType>(
      items.reduce((acc, current) => {
        if (current.type === type) {
          acc.push([current.slug, current]);
        }

        return acc;
      }, [])
    );
  }, [type, contentfulQuery]);
}
