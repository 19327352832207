import { ITripInstruction } from '@truckmap/common';
import { atom, atomFamily } from 'recoil';

type MapOutOfBoundProps = {
  handleResetPosition?: () => void;
  outOfBounds: boolean;
};

export const mapRestrictionsPanelVisibleAtom = atomFamily<boolean, string>({
  key: 'mapRestrictionsPanelVisibleAtom',
  default: false
});

export const mapOutOfBoundsAtom = atomFamily<MapOutOfBoundProps, string>({
  key: 'mapOutOfBoundsAtom',
  default: {
    outOfBounds: false
  }
});

export const currentUserSettingsAtom = atom({
  key: 'currentUserSettings',
  default: {
    equipmentWeights: 0,
    equipmentHeights: 0
  }
});

export const activeDirectionsAtom = atomFamily<ITripInstruction | null, string>({
  key: 'activeDirections',
  default: null
});

export const mapInvisibleLayersAtom = atomFamily<string[], string>({
  key: 'mapInvisibleLayers',
  default: []
});
