import { MappedItemEntries } from 'types/contentful/MappedItemEntries';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';
import { IUIComponentItemFields } from 'types/generated/contentful';

const mapEntriesByType = (componentItems: SerializedComponentItem[]): MappedItemEntries => {
  let entriesMap = new Map<IUIComponentItemFields['type'], SerializedComponentItem[]>();

  const iterator = (map: typeof entriesMap, item: SerializedComponentItem) => {
    if (!item) {
      return map;
    }

    map.set(item.type, [...(map.get(item.type) || []), item]);
    return map;
  };

  if (componentItems) {
    entriesMap = componentItems.reduce(iterator, entriesMap);
  }

  return entriesMap;
};

export { mapEntriesByType };
