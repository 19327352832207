import { IPlace } from '@truckmap/common';
import { getPlaceCoordinates } from 'lib/getClosestCity';
import { useMemo } from 'react';

export const useMapSourceCoordinates = (data: Partial<IPlace>[]) => {
  return useMemo(
    () =>
      data.map((place) => {
        return {
          id: place.id,
          coordinates: getPlaceCoordinates(place)
        };
      }),
    [data]
  );
};
