'use client';
import { resolveHref, RestrictedHref } from 'lib/resolveHref';
import Link, { LinkProps } from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React, { useCallback, useMemo } from 'react';

type ChildrenProp = { children: React.ReactNode };
type ParentClassNameProp = { className?: string };

export type NonPrefetchLinkProps = Omit<
  LinkProps,
  'prefetch' | 'passHref' | 'as' | 'replace' | 'legacyBehavior' | 'href'
> &
  ParentClassNameProp &
  RestrictedHref &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target'>;

// avoid next/link to decide prefetching
export const NonPrefetchLink: React.FC<NonPrefetchLinkProps & ChildrenProp> = React.memo(
  ({ children, href, scroll = true, locale, onClick, ...rest }) => {
    const router = useRouter();
    const pathname = usePathname();

    const resolvedHref = useMemo(() => {
      return resolveHref(href, locale, pathname);
    }, [href, locale]);

    const onClickHandler = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        onClick?.(e);
        // Routing is shallow by default
        router.push(resolvedHref, { scroll });
      },
      [router, pathname, resolvedHref, onClick, scroll]
    );

    return (
      <Link href={resolvedHref} onClick={onClickHandler} scroll={scroll} prefetch={false} {...rest}>
        {children}
      </Link>
    );
  }
);

NonPrefetchLink.displayName = 'NonPrefetchLink';
