'use client';
import { useCallback, useReducer, useState } from 'react';

export type TogglerType = ReturnType<typeof useToggle>;

const useToggle = (defaultValue = false) => {
  const [status, setStatus] = useState<boolean>(defaultValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createToggle = (visibility: boolean) =>
    useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e?: any) => {
        if (e?.stopPropagation) {
          e.stopPropagation();
        }
        visibility !== status && setStatus(visibility);
      },
      [status, setStatus]
    );

  return {
    enable: createToggle(true),
    disable: createToggle(false),
    toggle: createToggle(!status),
    status
  };
};

const useRawToggle = (defaultValue?: false) => useReducer((s) => !s, defaultValue);

export { useRawToggle, useToggle };
