import { ContentfulQuery, IPlace } from '@truckmap/common';
import { GlyphProps } from 'components/common/Glyph';
import { TranslationConfig } from 'hooks/useContentful';
import { MapControllers } from 'models/MapControllers';
import React from 'react';
import { TranslationKeys } from 'types/translationKeys';

import { IPage } from './generated/contentful';

export type GenericQuery = {
  query: { params: string[] };
};

export type AsHeading = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export type ContentfulQueryProps = {
  contentfulQuery?: ContentfulQuery;
};

export type LegacyContentfulPageProps = {
  page: IPage;
};

export type FlexChildClassName = {
  flex?: 'none' | 'auto' | 'initial' | '1';
};

export enum LegacyProminenceEnum {
  NEUTRAL = 'neutral',
  STRONG = 'strong',
  WEAK = 'weak'
}

export enum LegacyModeEnum {
  LIGHT = 'light',
  DARK = 'dark'
}

export type PlaceRoutesContext = {
  params: {
    locale?: string;
    relativePath: string[];
  };
};

export enum PlaceRoutes {
  UUID_REDIRECT = 'uuid',
  DETAILS = 'details',
  REVIEWS = 'reviews',
  LOCAL_MAP = 'local_map'
}

export type TruckmapStoreGenericAction<T> = { type: T };
export type TruckmapStoreAction<T, P> = TruckmapStoreGenericAction<T> & P;

export enum ErrorCodes {
  NOT_FOUND = 404,
  ERROR = 500
}

export type TextProps = {
  primaryText: string;
  secondaryText?: string;
};

export enum KeyCodes {
  ENTER = 13,
  ESCAPE = 27,
  SPACE = 32,
  TAB = 9
}

export type CommonIcon = {
  icon?: React.MemoExoticComponent<(props: GlyphProps) => JSX.Element>;
};

export type LegacyProminenceType = {
  prominence?: 'neutral' | 'strong' | 'weak';
};

export enum SizeOptions {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL'
}

export enum ShipmentTypes {
  DROP_OFF = 'Drop',
  PICKUP = 'Pick'
}

export enum ShipmentActivityTypes {
  IMPRESSIONS = 'Impressions',
  CLAIMS = 'Claims',
  VIEWS = 'Views'
}

export type PlaceProp = {
  place: IPlace;
};

export type FullWidthProp = {
  fullWidth?: boolean;
};

export type TranslationsType = (key: TranslationKeys, config?: TranslationConfig) => string;

export type MapControllersProp = {
  controllerId: MapControllers;
};

export type StaticPageProps = {
  page: IPage;
  contentfulQuery: ContentfulQuery;
};
