'use client';
import { ContentfulUiComponent, ContentfulUiComponentItem } from '@truckmap/common';
import { getUiComponentSection } from 'lib/contentful/getUiComponentSection';
import { createContext, useContext, useMemo } from 'react';
import { ContentfulQueryProps } from 'types/common';
import { IPage, IUIComponentFields, IUIComponentItemFields } from 'types/generated/contentful';
import { GeneratedSectionLayouts } from 'types/translationKeys';

export const ContentfulContext = createContext<ContentfulQueryProps>(null);

export const useContentfulQuery = () => useContext(ContentfulContext)?.contentfulQuery;

export const useContentfulUiComponentFromPage = (
  page: IPage,
  sectionLayoutName: GeneratedSectionLayouts
) => {
  return useMemo(
    () =>
      page.fields.uiComponents.find(
        ({ fields: { sectionLayout } }) => sectionLayout === sectionLayoutName
      ),
    [page]
  );
};

export const useContentfulUiComponentFromQuery = (sectionLayoutName: GeneratedSectionLayouts) => {
  const { uiComponentCollection = null } = useContentfulQuery();

  return useMemo(
    () =>
      uiComponentCollection?.items?.find(
        ({ sectionLayout }) => sectionLayout === sectionLayoutName
      ),
    [uiComponentCollection]
  );
};

export const useContentfulUiComponentItems = (
  component: ContentfulUiComponent | ContentfulUiComponentItem,
  componentItemType: IUIComponentFields['type'] | IUIComponentItemFields['type']
) => {
  return useMemo(
    () => component.itemsCollection.items.filter(({ type }) => type === componentItemType),
    [component, componentItemType]
  );
};

export const useContentfulUiComponentSections = () => {
  const { uiComponentCollection = null } = useContentfulQuery();

  return useMemo(() => getUiComponentSection(uiComponentCollection), [uiComponentCollection]);
};
