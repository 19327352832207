import { IPlaceSummary } from '@truckmap/common';
import { formatPricePerGallon } from 'lib/place/formatPricePerGallon';

export const getPrettySummary = (placeSummary: IPlaceSummary): IPlaceSummary =>
  Object.keys(placeSummary).reduce((prettySummary, summaryItem) => {
    if (typeof placeSummary[summaryItem] !== 'string' || placeSummary[summaryItem] === 'true') {
      return prettySummary;
    }

    if (summaryItem === 'dieselPrice') {
      return {
        ...prettySummary,
        [summaryItem]: formatPricePerGallon(placeSummary[summaryItem])
      };
    }

    return {
      ...prettySummary,
      [summaryItem]: placeSummary[summaryItem]
    };
  }, {});
