/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors, IPlace, IPlaceMetaType, IPlaceType, LatLonObject } from '@truckmap/common';
import { featureCollection } from '@turf/helpers';
import { Feature, Geometry } from 'geojson';
import { PlaceIconAnnotation, usePlaceIcon } from 'hooks/usePlace';
import { getStaticImageUrl } from 'lib/image/getImageUrl';

export type MapSourceProps = {
  mapCoordinatesSource: { id: string; coordinates: LatLonObject }[];
  data: Partial<IPlace>[];
  defaultAsset: IPlaceType | IPlaceMetaType;
  placeSlug: string;
  layerId: string;
} & Omit<unknown, 'type' | 'data' | 'generateId' | 'clusterProperties'>;

export const useMapSource = ({
  mapCoordinatesSource,
  data,
  defaultAsset,
  placeSlug,
  layerId,
  cluster = true,
  clusterRadius = 50,
  ...geoJsonProps
}: MapSourceProps & { cluster?: boolean; clusterRadius?: number }) => {
  const generatePlaceClusterPoints = () =>
    featureCollection(
      mapCoordinatesSource.map<Feature<Geometry>>((placeCoordinates) => {
        const place = data.find((place) => place.id === placeCoordinates.id);
        const placeIconConfig = usePlaceIcon(place as IPlace);
        const mapIconSrc = ({ type, slug }: PlaceIconAnnotation) => `icons/${type}/${slug}.map.png`;

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              placeCoordinates?.coordinates.longitude as number,
              placeCoordinates?.coordinates.latitude as number
            ]
          },
          properties: {
            // we don't optimize those images because canvas tag fails with avif and webp
            icon: getStaticImageUrl(mapIconSrc(placeIconConfig)),
            defaultIcon: getStaticImageUrl(
              mapIconSrc(
                defaultAsset?.asset?.slug
                  ? { slug: defaultAsset?.asset?.slug, type: 'place-types' }
                  : { slug: placeSlug, type: 'place-types' }
              )
            ),
            defaultAsset,
            iconBackground: colors.gray020,
            iconSize: 0.5,
            slug: placeSlug,
            place,
            placeId: place.id,
            name: place.name,
            address: place.address
          }
        };
      })
    );

  return {
    sources: (layers = []): Map<string, any> => {
      const map = new Map<string, any>(layers);

      map.set(layerId, {
        type: 'geojson',
        data: generatePlaceClusterPoints(),
        generateId: true,
        cluster,
        clusterRadius,
        clusterProperties: {
          ...(defaultAsset && { hasDefaultIcon: ['get', 'defaultAsset'] }),
          icon: ['get', 'icon'],
          defaultIcon: ['get', 'defaultIcon']
        },
        ...geoJsonProps
      });

      return map;
    },
    actions: {
      generatePlaceClusterPoints
    }
  };
};
