import { LatLon } from '@truckmap/common';
import Cookies from 'js-cookie';
import { DefaultLocationWaypoint } from 'recoil/routePlanner/routePlannerAtom';
import { truckMapConfig } from 'truckMapConfig';

export type LatLonWithPreciseInfo = LatLon & { precise: boolean };

export function decodeGeoCookie(geoString: string) {
  const [latitude, longitude] = decodeURIComponent(geoString).split(',');

  return {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude)
  };
}

export function getAccurateGeolocation(): Promise<LatLonWithPreciseInfo> {
  const unpreciseLocation = getGeolocationFromCookie();

  // fallback for non compatible browsers
  if (!('geolocation' in navigator)) {
    return Promise.resolve(unpreciseLocation);
  }

  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (accurateGeo) => {
        resolve({
          longitude: accurateGeo.coords.longitude,
          latitude: accurateGeo.coords.latitude,
          precise: true
        });
      },
      // in case user does not accept sharing location or something goes wrong, we fallback to CDN cookie
      () => {
        resolve(unpreciseLocation);
      }
    );
  });
}

export function getDefaultLocation() {
  return decodeGeoCookie(truckMapConfig.defaultGeo);
}

export function getGeolocationFromCookie(): LatLonWithPreciseInfo {
  const geoString = Cookies.get(truckMapConfig.cookies.geo);

  if (!geoString) {
    return { ...getDefaultLocation(), precise: false };
  }

  return { ...decodeGeoCookie(geoString), precise: false };
}

export function isCurrentLocationWaypointObject(waypoint: DefaultLocationWaypoint) {
  return waypoint.objectType === 'currentLocation';
}

export function isSupportedCountry() {
  const supportedCountry = Cookies.get(truckMapConfig.cookies.supportedCountry);

  return supportedCountry !== '0';
}
